<template>
<v-col v-resize="resize">
  
  <v-row style="padding-bottom:50px;" class="dashboard">
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto header-section text-center mt-0 mb-5">
        <v-img src="../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="180"/>
        <v-img src="../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="150"/>
        <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2">
          <small>La monnaie pirate</small>
        </h1>
    </v-col>

    <!-- <v-col cols="11" sm="11" md="8" lg="7" class="mx-auto pb-0 d-none d-md-flex" 
           style="border-top:1px dashed #fbc02d;margin-top:10px;"></v-col> -->

    <v-col cols="11" sm="11" md="10" lg="9" class="mx-auto pt-0">
      <v-row>

        <template v-if="$store.state.auth.isLogin">
          <v-col cols="12" md="6" class="mt-5">
            <WalletCard :wallet="$store.state.auth.user.walletMain"></WalletCard>
          </v-col>
          <v-col cols="12" v-for="wallet in $store.state.auth.user.walletsDeposit" :key="wallet._id"
                :md="$store.state.auth.user.walletsDeposit.length <= 2 ? 6 : 4" class="mt-5">
            <WalletCard :wallet="wallet"></WalletCard>
          </v-col>
          <v-col cols="12" v-for="company in $store.state.auth.user.companies" :key="company._id" class="mt-5">
            <WalletCard :wallet="company.walletDeposit" :ownerCompany="company"></WalletCard>
          </v-col>
        </template>

        
        <v-col cols="12" sm="6" v-if="!$store.state.auth.isLogin">
          <v-card to="/login" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-login</v-icon><br>
            <v-card-title class="font-main dash-title"> Se connecter</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Accédez à votre compte<br>pour gérer votre monnaie<br>et accéder à tous les outils.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="!$store.state.auth.isLogin">
          <v-card to="/register" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-pirate</v-icon><br>
            <v-card-title class="font-main dash-title"> Devenir un pirate</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              <b>Créez votre compte gratuit</b><br>en quelques secondes, et recevez<br>vos 10 premiers PRT aujourd'hui !
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/market" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-cart-arrow-right</v-icon><br>
            <v-card-title class="font-main dash-title"> Marché pirate</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Échangez ce que vous voulez en Monnaie Pirate !
              Parcourez les annonces des autres pirates,
              et publiez vos propres annonces en PRT !
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/publish-offer" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-file-plus-outline</v-icon><br>
            <v-card-title class="font-main dash-title"> Créer une annonce</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Vous avez quelque chose à vendre en PRT ?
              Publiez votre annonce dès maintenant, et faites en profiter les autres pirates !
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/map" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-map-search-outline</v-icon><br>
            <v-card-title class="font-main dash-title"> La carte</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Explorez la carte pour découvrir les pirates qui se trouvent autour de vous,
              et repérer les lieux où dépenser vos PRT !
            </v-card-text>
          </v-card>
        </v-col>
        
        
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/game/battle/menu" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-sail-boat</v-icon><br>
            <v-card-title class="font-main dash-title"> Bataille navale</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Jouez à la bataille navale, en solo ou multijoueur,
              et tentez de vaincre votre adversaire pour remporter la mise en PRT !
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="$store.state.auth.isLogin">
          <v-card to="/create-company" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-briefcase-plus-outline</v-icon><br>
            <v-card-title class="font-main dash-title"> Référencer mon activité</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Vous souhaitez attirer plus de clients pirates, pour gagner plus de PRT ?<br>
              Référencez-vous pour gagner en visibilité, et obtenir un compte de dépôt dédié à votre activité ! 
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="!$store.state.auth.isLogin">
          <v-card class="game-menu-btn text-left  bg-paper " elevation="0">
            <!-- <v-icon x-large color="primary">mdi-sail-boat</v-icon><br> -->
            <v-card-title class="font-main dash-title text-center d-block">~ Synopsis ~</v-card-title>
            <v-card-text class="font-papyrus mt-2 pb-0 text-paper">
              L'histoire raconte qu'un jour, depuis les tréfonds du Royaume des Morts, un couple de pirates inventa une nouvelle monnaie révolutionnaire,
            dans le but de pirater le système capitaliste, qui commençait sérieusement à menacer l'existence même du monde des Vivants.
            <br><br>
            A force d'observer l'humanité se développer, année après année, ils avaient commencé à imaginer un nouveau système hors du système,
            avec de nouvelles règles, et de nouveaux objectifs.
            <br><br>
            Leur but était de créer un système économique plus égalitaire et plus stable que tout ce qui avait existé auparavant.
            Et grâce à la puissance d'internet, les deux pirates avaient finit par trouver la formule mathématique qui allait tout changer...
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card to="/book" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-book-open-page-variant-outline</v-icon><br>
            <v-card-title class="font-main dash-title"> Journal de bord</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Parcourez le Journal de bord pour découvrir toute l'histoire de la Monnaie Pirate !
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card to="/code-des-pirates" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-feather</v-icon><br>
            <v-card-title class="font-main dash-title">Le code des pirates</v-card-title>
            <v-card-text class="font-papyrus mt-2">
             Découvrez les règles communes à tous les pirates !
            </v-card-text>
          </v-card>
        </v-col>

        
        <v-col cols="12" v-if="!$store.state.auth.isLogin">
          <v-card to="/game/battle/menu" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-sail-boat</v-icon><br>
            <v-card-title class="font-main dash-title"> Bataille navale</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Jouez à la bataille navale, en solo ou multijoueur,
              et tentez de vaincre votre adversaire pour remporter la mise en PRT !
            </v-card-text>
          </v-card>
        </v-col>


        <v-col cols="12" lg="10" class="mx-auto px-0" v-if="!$store.state.auth.isLogin">
          <h1 class="font-main dash-title text-center d-block py-5"> Une petite visite <span class="d-inline-block">moussaillon ?</span></h1>
          <v-card class="mt-3 pa-2" elevation="0" style="min-height:unset!important;"  :style="'height:'+(carouselHeight+18)+'px'">
            <!-- <v-img src="../assets/img/homepage/slider/wallet5.png" contain lazy-src/><br> -->
            <v-carousel :height="carouselHeight" cycle continuous  hide-delimiters>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite1.png" @load="resize(500)" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite2.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite3.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite4.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite5.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite6.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite7.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite8.png" contain></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img src="../assets/img/homepage/slider/visite9.png" contain></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>

          
        
        <v-col cols="12" id="qr-container" v-if="!isTheApp && !$store.state.auth.isLogin">
          <v-card  to="/install-app-android"
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-tablet-cellphone</v-icon>
            <v-icon x-large color="primary" class="ml-1">mdi-android</v-icon><br>
            <v-card-title class="font-main dash-title"> App mobile</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Installez l'appli sur votre téléphone Android,<br class="d-none d-sm-inline">
              pour toujours garder votre Monnaie Pirate à portée de main !<br>
              <!-- link:{{ linkInstallApp }} -->
              <v-btn x-large class="mt-5" style="font-size:16px !important;" 
                      color="blue" v-if="isAndroid" to="/install-app-android">
                <v-icon class="mb-0 mr-1">mdi-tablet-cellphone</v-icon>
                Installer
                <v-icon class="mb-0 ml-1" >mdi-arrow-right-circle</v-icon>
              </v-btn>
              <v-row v-else>
                <v-col cols="12" md="8" lg="4" class=" mx-auto text-center mt-5" id="qrcode">
                  <qr-code class="d-inline-block mb-2" v-if="qrSize != null && linkInstallApp != null" 
                          :text="linkInstallApp" :size="qrSize">
                  </qr-code>
                  <br>
                  Scannez le QR-Code pour télécharger l'application !
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        
        

        <v-col cols="12" v-if="$store.state.auth.isLogin" >
          <v-card to="/pay" class="game-menu-btn text-center" elevation="0">
            <v-img src="../assets/img/coin-badge.jpg" class="d-inline-block" style="border-radius:100%;margin-bottom:-50px;" width="60"/>
            <br>
            <v-card-title class="font-main dash-title">Devenir membre d'équipage</v-card-title>
            <v-card-text class="font-papyrus mt-2">
             Participez au financement de la Monnaie Pirate pour obtenir votre badge
             et accéder aux parties multijoueurs...
              
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="12" sm="6" lg="4" v-if="$store.state.auth.isLogin">
          <v-card  to="/share" class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-ear-hearing</v-icon><br>
            <v-card-title class="font-main dash-title primary--text"> Bouche à oreille</v-card-title>
            <v-card-text class="font-papyrus mt-2 primary--text">
              Plein de contenus à partager sur les réseaux, ou à imprimer,
              pour faire marcher le bouche à oreille, et faire connaître la Monnaie Pirate
              le plus largement possible !
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="12" sm="6" lg="4" v-if="$store.state.auth.isLogin" >
          <v-card to="/recruit" style="border-color:red"
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="red">mdi-qrcode</v-icon><br>
            <v-card-title class="font-main dash-title red--text">Parrainage</v-card-title>
            <v-card-text class="font-papyrus mt-2 red--text">
              Partagez votre code de parrainage pour recruter de nouveaux pirates !<br>
              Gagnez 1 PRT à chaque nouvelle inscription !<br>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" lg="4" v-if="$store.state.auth.isLogin">
          <v-card  style="border-color:grey;cursor:not-allowed;" title="module en cours de développement"
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="grey darken-2">mdi-bed</v-icon><br>
            <v-card-title class="font-main dash-title grey--text text--darken-2"> Air P & P</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Notre service d'hébergement entre pirates !<br>
              Proposez ou recherchez un hébergement à louer en PRT !
              <br>
              <small>(module en cours de développement)</small>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="3" class="mx-auto" v-if="$store.state.auth.isLogin">
          <v-card :to="$store.state.auth.user.inBFC ? '/inbox-crew' : '/init-account/2'" style="background: black !important;min-height:unset!important;" 
                  class="game-menu-btn text-center pb-0" elevation="0">
            <v-img src="../assets/img/black_fire_crew-600.png" class="d-inline-block" lazy-src/><br>
          </v-card>
        </v-col>
        
      </v-row>
    </v-col>
<!-- 
    <v-col v-if="configJson.emailMode == 'dev'">
    <pre>{{ configJson }}</pre>
    </v-col> -->
  </v-row>

  <v-row>
    <v-col class="pa-0">
      <Footer/>
    </v-col>
  </v-row>
</v-col>
</template>

<style>
  .dashboard .game-menu-btn{
    border: 1px dashed #fbc02d !important;
    height:unset!important;
    min-height:300px!important;
  }
  .dashboard .game-menu-btn i{
    margin-bottom:-60px;
  }
  .dashboard .dash-title{
    font-size:45px;
    margin-bottom:30px;
  }

  .bg-paper{
    border:0px !important
  }
  .text-paper{
    font-size:19px !important;
    line-height:25px;
    padding:30px !important;
    border-radius:8px;
  }
  #qrcode img{
    border: 5px solid white;
    width:100% !important;
    max-width:100% !important;
  }

  @media (max-width: 960px){
    .dashboard .game-menu-btn {
      height:unset!important;
      min-height:300px!important;
    } 
  .text-paper{
    font-size:13px !important;
    line-height:18px;
    padding:10px !important;
    border-radius:8px;
  }
  }
</style>

<script>

import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
const config = require('../config/' + process.env.NODE_ENV)
import Footer from '@/components/main-layout/Footer.vue'
import WalletCard from '@/components/versions/wallet/WalletCard.vue'

import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'dashboard',
  components: { Footer, WalletCard, 'qr-code' : VueQRCodeComponent },
  data: () => ({
    carouselHeight: 'auto',
    qrSize: null,
    linkInstallApp: null
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/dashboard')
    this.resize(3000)
    axios.get("/data/apk-infos").then((res)=>{
      this.linkInstallApp = res.data.appAndroidUrl
    })
  },
  methods: {
    resize(time){
      let t = time != null ? time : 1500
      this.carouselHeight = "auto"
      //this.qrSize  = null
      setTimeout(()=>{
        this.qrCodeSize()
        let el = document.getElementsByClassName("v-carousel")[0]
        if(el==null) return
        if(el.offsetHeight == 0) return //this.resize(2000)
        else this.carouselHeight = el.offsetHeight 
        console.log("this.carouselHeight", this.carouselHeight)
      }, t)
    },
    qrCodeSize(){ 
      this.qrSize = 400 //document.getElementById('qr-container').offsetWidth - 100 
      console.log("qrCodeSize", this.qrSize)
    },

  },
  computed: {
    configJson(){
      return config
    },
    isAndroid(){
      return navigator.userAgent.match(/Android/i)
    },
    // linkInstallApp(){
    //   return this.$store.state.auth.appAndroidUrl
    // },
    isTheApp(){ return config.appAndroidVersion != null}
    
  }
}
</script>
